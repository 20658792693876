const initalState = {
  isLoading: false,
  newAddedMaleProfiles: [],
  newAddedFemaleProfiles: [],
  maleProfiles: [],
  allProfiles: [],
  femaleProfiles: [],
  filterAgeStart: 0,
  filterAgeStop: 0,
  filterGender: "",
  filterKullam: "",
};

const reducer = (state = initalState, action) => {
  switch (action.type) {
    case "ON_LOADING":
      return {
        ...state,
        isLoading: true,
      };
    case "OFF_LOADING":
      return {
        ...state,
        isLoading: false,
      };
    case "NEW_ADDED_MALE_PROFILE":
      return {
        ...state,
        newAddedMaleProfiles: action.payload,
      };
    case "NEW_ADDED_FEMALE_PROFILE":
      return {
        ...state,
        newAddedFemaleProfiles: action.payload,
      };
    case "ALLPROFILE":
      return {
        ...state,
        allProfiles: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
