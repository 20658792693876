import React, { Suspense } from "react";
import ReactDom from "react-dom";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import "./index.css";
import store from "../src/redux/store";
import { Provider } from "react-redux";
import axios from "axios";
import Loader from "./components/Loader/Loader";
const AllProfiles = React.lazy(() =>
  import("./screens/AllProfiles/AllProfiles")
);
const Home = React.lazy(() => import("./screens/Home/Home"));
const ParticularProfile = React.lazy(() =>
  import("./screens/ParticularProfile/ParticularProfile")
);

const App = () => {
  axios.defaults.baseURL = "https://api.vasanthammatrimonyudt.com/";
  // axios.defaults.baseURL = "http://172.20.10.2:4000/";
  return (
    <Provider store={store}>
      <Router>
        <Suspense fallback={<Loader />}>
          {/* <Route exact path="/" component={Loader} /> */}
          <Route exact path="/" component={Home} />
          <Route path="/allprofiles/:gender" component={AllProfiles} />
          <Route path="/particularprofile/:id" component={ParticularProfile} />
        </Suspense>
      </Router>
    </Provider>
  );
};

export default App;

ReactDom.render(<App />, document.querySelector("#root"));
